@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed:300i,400,700);
/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@keyframes rightandLeft {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext2 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate3d(0px, 100%, 0px); }
  100% {
    position: fixed; } }

@keyframes upanddown {
  0% {
    position: fixed;
    transform: translateY(0); }
  50% {
    position: fixed;
    transform: translateY(-29%); }
  100% {
    position: fixed;
    transform: translateY(0%); } }

@keyframes downandup {
  0% {
    transform: translateY(-150%); }
  50% {
    transform: translateY(-175%); }
  100% {
    transform: translateY(-150%); } }

#root {
  overflow-x: hidden; }
  #root .cookieConsent {
    font-family: 'IBM Plex Sans Condensed', sans-serif !important; }
    @media (min-width: 992px) {
      #root .cookieConsent {
        width: 900px !important;
        margin: 0 auto !important;
        right: 0 !important;
        bottom: 30px !important;
        text-align: center !important; } }

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px; }

html {
  background-color: #181818; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

* {
  box-sizing: border-box; }

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@keyframes rightandLeft {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext2 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate3d(0px, 100%, 0px); }
  100% {
    position: fixed; } }

@keyframes upanddown {
  0% {
    position: fixed;
    transform: translateY(0); }
  50% {
    position: fixed;
    transform: translateY(-29%); }
  100% {
    position: fixed;
    transform: translateY(0%); } }

@keyframes downandup {
  0% {
    transform: translateY(-150%); }
  50% {
    transform: translateY(-175%); }
  100% {
    transform: translateY(-150%); } }

a {
  text-decoration: none;
  color: #e7e7e7;
  cursor: pointer; }

*:focus,
*:active {
  outline: none; }

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@keyframes rightandLeft {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext2 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate3d(0px, 100%, 0px); }
  100% {
    position: fixed; } }

@keyframes upanddown {
  0% {
    position: fixed;
    transform: translateY(0); }
  50% {
    position: fixed;
    transform: translateY(-29%); }
  100% {
    position: fixed;
    transform: translateY(0%); } }

@keyframes downandup {
  0% {
    transform: translateY(-150%); }
  50% {
    transform: translateY(-175%); }
  100% {
    transform: translateY(-150%); } }

.work .jobs {
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  font-size: 1rem;
  width: 100%; }
  @media (min-width: 992px) {
    .work .jobs {
      display: flex; } }
  @media (min-width: 992px) {
    .work .jobs .job-roles {
      margin-right: 8rem; } }
  .work .jobs .job {
    font-weight: 700;
    padding-left: 0px;
    white-space: nowrap;
    overflow: hidden; }
    .work .jobs .job h1 sup {
      font-size: 0.2em;
      color: #ebc74e;
      margin-left: 10px; }

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@keyframes rightandLeft {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext2 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate3d(0px, 100%, 0px); }
  100% {
    position: fixed; } }

@keyframes upanddown {
  0% {
    position: fixed;
    transform: translateY(0); }
  50% {
    position: fixed;
    transform: translateY(-29%); }
  100% {
    position: fixed;
    transform: translateY(0%); } }

@keyframes downandup {
  0% {
    transform: translateY(-150%); }
  50% {
    transform: translateY(-175%); }
  100% {
    transform: translateY(-150%); } }

.modal,
.job-modal {
  width: 100%;
  padding: 20px;
  font-weight: 700;
  position: relative; }
  .modal a,
  .job-modal a {
    text-decoration: underline;
    font-size: 0.83em;
    text-transform: uppercase; }
  .modal .job-about,
  .modal .job,
  .job-modal .job-about,
  .job-modal .job {
    padding: 0;
    color: white;
    font-size: 1rem;
    font-weight: normal; }
    .modal .job-about.rightAlign,
    .modal .job.rightAlign,
    .job-modal .job-about.rightAlign,
    .job-modal .job.rightAlign {
      text-align: right; }
      .modal .job-about.rightAlign p,
      .modal .job.rightAlign p,
      .job-modal .job-about.rightAlign p,
      .job-modal .job.rightAlign p {
        text-align: inherit;
        margin-left: auto; }
    .modal .job-about h3,
    .modal .job h3,
    .job-modal .job-about h3,
    .job-modal .job h3 {
      color: #181818;
      text-shadow: -1px 0 #b09759, 0 1px #b09759, 1px 0 #b09759, 0 -1px #b09759; }
    @media (min-width: 992px) {
      .modal .job-about,
      .modal .job,
      .job-modal .job-about,
      .job-modal .job {
        font-size: 1.5rem; }
        .modal .job-about p,
        .modal .job p,
        .job-modal .job-about p,
        .job-modal .job p {
          width: 70%; } }
  .modal .job-period,
  .job-modal .job-period {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 400;
    color: #e7e7e7;
    border-left: 3px solid #ebc74e;
    padding-left: 10px;
    margin-left: 10px; }
    .modal .job-period span,
    .job-modal .job-period span {
      font-size: 1.5rem; }
    @media (max-width: 991px) {
      .modal .job-period,
      .job-modal .job-period {
        padding-right: 4rem; } }
    @media (min-width: 992px) {
      .modal .job-period,
      .job-modal .job-period {
        font-size: 3rem; }
        .modal .job-period span,
        .job-modal .job-period span {
          font-size: 2rem; } }
    .modal .job-period sup,
    .job-modal .job-period sup {
      font-size: 0.5rem;
      text-transform: uppercase;
      color: #47beae;
      text-shadow: none; }
      @media (min-width: 992px) {
        .modal .job-period sup,
        .job-modal .job-period sup {
          font-size: 1rem; } }
  .modal .job-company,
  .job-modal .job-company {
    font-size: 10.5rem;
    flex: 1 1;
    padding: 1%;
    text-align: left;
    text-transform: uppercase;
    color: #181818;
    text-shadow: -1px 0 #ebc74e, 0 1px #ebc74e, 1px 0 #ebc74e, 0 -1px #ebc74e; }
  @media (min-width: 992px) {
    .modal,
    .job-modal {
      height: 100%;
      padding: 35px 50px; }
      .modal .job-header,
      .job-modal .job-header {
        display: flex; } }

/* Small devices (portrait tablets and large phones, 600px and up) */
/* Medium devices (landscape tablets, 768px and up) */
/* Large devices (laptops/desktops, 992px and up) */
/* Extra large devices (large laptops and desktops, 1200px and up) */
@keyframes rightandLeft {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-29%); }
  100% {
    transform: translateX(0%); } }

@keyframes sliderArrowNext2 {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(29%); }
  100% {
    transform: translateX(0%); } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes slideUp {
  0% {
    transform: translate3d(0px, 100%, 0px); }
  100% {
    position: fixed; } }

@keyframes upanddown {
  0% {
    position: fixed;
    transform: translateY(0); }
  50% {
    position: fixed;
    transform: translateY(-29%); }
  100% {
    position: fixed;
    transform: translateY(0%); } }

@keyframes downandup {
  0% {
    transform: translateY(-150%); }
  50% {
    transform: translateY(-175%); }
  100% {
    transform: translateY(-150%); } }

.close {
  width: 50px;
  height: 50px;
  right: 20px;
  top: 20px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 200; }
  .close .icon {
    background-image: url(/static/media/close.e667d00a.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%; }
    @media (min-width: 992px) {
      .close .icon {
        transform: scale(1.4);
        height: 30px;
        width: 30px;
        right: 30px; } }

